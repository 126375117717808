import React, { useEffect, useRef, useState } from 'react';
import searchPng from '../assets/PNG/icn_search.png';
import data from '../data.json';

const Search = (props) => {
  const [searchPopup, setSearchPopup] = useState('search-popup');
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState('');
  const searchInput = useRef(null);
  const searchResults = useRef(null);
  const flattenedData = [];
  const bottomRef = useRef(null);

  const flattenData = (e) => {
    data.tables.forEach(function(table, index) {
      table.desks.forEach(function(desk, deskIndex) {
        desk.seats.forEach(function(seat, seatIndex) {
          let searchableData = {};
          searchableData.index = index;
          searchableData.no = desk.desk;
          searchableData.table = table.table;
          searchableData.name = seat.name;
          flattenedData.push(searchableData);
        });
      });
    });
  }

  const logEventToCSV = async (eventData) => {
    const csvData = `${eventData.date},${eventData.action},${eventData.table}\n`;

    try {
        const response = await fetch('./events.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain', // Or 'application/json' if sending JSON data
            },
            body: csvData,
        });

        if (response.ok) {
        } else {
        }
    } catch (error) {
    }
  };

  const closePopup = (e) => {

    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'close search'
    };

    logEventToCSV(eventData);

    setSearchPopup('search-popup');
    e.preventDefault();
  }

  const openPopup = (e) => {

    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'open search'
    };

    logEventToCSV(eventData);

    setSearchPopup('search-popup active');
    searchInput.current.focus();
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    e.preventDefault();
  }

  const handlePopup = (index, table, name) => (e) => {
    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'Search Result',
      table: table,
      name: name
    };

    logEventToCSV(eventData);

    props.setIntroZoom('fadeOut zoomedOut');
    props.setPopup('popup active');
    props.setCarouselIndex(index);
    setSearchPopup('search-popup');
   
    e.preventDefault();
  }
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchTerm.length > 2) {
        flattenData();

        const currentDate = new Date().toISOString().split('T')[0];

        const eventData = {
          date: currentDate,
          action: 'search: ' + searchTerm
        };

        logEventToCSV(eventData);

        let results = [];
        flattenedData.forEach(function(entry, index) {
          if(entry.name.search(new RegExp(searchTerm, "i")) > -1) {
            results.push(entry);
          }
        });

        let tmpResults = () => (
          <>
            {results && results.map((res, index) => (
              <div className="result" onClick={handlePopup(res.index, res.table, res.name)} key={`result-${index}`}><span className='no'>{res.no}</span><span className='name'>{res.name}</span></div>
            ))}
          </>
        );

        setResults(tmpResults);
      }
      
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const popup = () => (
    <div className={searchPopup}>
      <div className='search-bg' onClick={closePopup}></div> 
      <div className='search-box'> 
        <div className="search-icon">
          <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><g id="Ebene_9"><g><circle id="Ellipse_1" className="cls-2" cx="19" cy="19" r="19"/><path id="Icon_awesome-search" className="cls-1" d="m28.13,25.57l-3.78-3.77c-.17-.17-.4-.27-.64-.27h-.62c2.68-3.43,2.07-8.38-1.36-11.06-3.43-2.68-8.38-2.07-11.06,1.36-2.68,3.43-2.07,8.38,1.36,11.06,2.85,2.22,6.84,2.22,9.69,0v.62c0,.24.09.47.27.64l3.78,3.78c.35.35.93.36,1.28,0,0,0,0,0,0,0l1.07-1.07c.35-.36.35-.93,0-1.29h0Zm-11.25-4.05c-2.68,0-4.85-2.17-4.85-4.85s2.17-4.85,4.85-4.85,4.85,2.17,4.85,4.85h0c0,2.68-2.17,4.85-4.84,4.85,0,0,0,0,0,0Z"/></g></g></svg>
        </div>
        <div className="search-content">
          <input type="search" name="searchQuery" placeholder="search" ref={searchInput} onChange={(e) => setSearchTerm(e.target.value)} />
          <div className="search-results" ref={searchResults}>
            {results}
          </div>
        </div>
      </div>
      
    </div>
  );

  return (
    <div className='search'>
      <img src={searchPng} alt="Logo" height="auto" onClick={openPopup} />
      {popup()}
      <div ref={bottomRef} />
    </div>
  )
}

export default Search