// import assets
import desk_small_horizontal from '../assets/PNG/desk_small_horizontal@2x.png';
import desk_small_horizontal_hover from '../assets/PNG/desk_small_horizontal_hover@2x.png';
import desk_small_vertical from '../assets/PNG/desk_small_vertical@2x.png';
import desk_small_vertical_hover from '../assets/PNG/desk_small_vertical_hover@2x.png';
import desk_zoom_0102 from '../assets/PNG/desk_zoom_0102@2x.png';
import desk_zoom_0102_hover from '../assets/PNG/desk_zoom_0102_hover@2x.png';
import desk_zoom_0304 from '../assets/PNG/desk_zoom_0304@2x.png';
import desk_zoom_0304_hover from '../assets/PNG/desk_zoom_0304_hover@2x.png';
import desk_zoom_0506 from '../assets/PNG/desk_zoom_0506@2x.png';
import desk_zoom_0506_hover from '../assets/PNG/desk_zoom_0506_hover@2x.png';
import desk_zoom_0708 from '../assets/PNG/desk_zoom_0708@2x.png';
import desk_zoom_0708_hover from '../assets/PNG/desk_zoom_0708_hover@2x.png';
import desk_zoom_0910 from '../assets/PNG/desk_zoom_0910@2x.png';
import desk_zoom_0910_hover from '../assets/PNG/desk_zoom_0910_hover@2x.png';
import desk_zoom_1112 from '../assets/PNG/desk_zoom_1112@2x.png';
import desk_zoom_1112_hover from '../assets/PNG/desk_zoom_1112_hover@2x.png';
import desk_zoom_1314 from '../assets/PNG/desk_zoom_1314@2x.png';
import desk_zoom_1314_hover from '../assets/PNG/desk_zoom_1314_hover@2x.png';
import desk_zoom_1516 from '../assets/PNG/desk_zoom_1516@2x.png';
import desk_zoom_1516_hover from '../assets/PNG/desk_zoom_1516_hover@2x.png';
import desk_zoom_1718 from '../assets/PNG/desk_zoom_1718@2x.png';
import desk_zoom_1718_hover from '../assets/PNG/desk_zoom_1718_hover@2x.png';
import desk_zoom_1920 from '../assets/PNG/desk_zoom_1920@2x.png';
import desk_zoom_1920_hover from '../assets/PNG/desk_zoom_1920_hover@2x.png';
import desk_zoom_2122 from '../assets/PNG/desk_zoom_2122@2x.png';
import desk_zoom_2122_hover from '../assets/PNG/desk_zoom_2122_hover@2x.png';
import desk_zoom_2324 from '../assets/PNG/desk_zoom_2324@2x.png';
import desk_zoom_2324_hover from '../assets/PNG/desk_zoom_2324_hover@2x.png';
import desk_zoom_2526 from '../assets/PNG/desk_zoom_2526@2x.png';
import desk_zoom_2526_hover from '../assets/PNG/desk_zoom_2526_hover@2x.png';
import desk_zoom_2728 from '../assets/PNG/desk_zoom_2728@2x.png';
import desk_zoom_2728_hover from '../assets/PNG/desk_zoom_2728_hover@2x.png';
import desk_zoom_2930 from '../assets/PNG/desk_zoom_2930@2x.png';
import desk_zoom_2930_hover from '../assets/PNG/desk_zoom_2930_hover@2x.png';
import desk_zoom_3132 from '../assets/PNG/desk_zoom_3132@2x.png';
import desk_zoom_3132_hover from '../assets/PNG/desk_zoom_3132_hover@2x.png';
import desk_zoom_3334 from '../assets/PNG/desk_zoom_3334@2x.png';
import desk_zoom_3334_hover from '../assets/PNG/desk_zoom_3334_hover@2x.png';
import desk_zoom_3536 from '../assets/PNG/desk_zoom_3536@2x.png';
import desk_zoom_3536_hover from '../assets/PNG/desk_zoom_3536_hover@2x.png';
import desk_meeting from '../assets/PNG/desk_meeting.png';
import desk_meeting_hover from '../assets/PNG/desk_meeting_hover.png';
import desk_meeting_zoom from '../assets/PNG/desk_meeting_zoom.png';
import desk_meeting_zoom_hover from '../assets/PNG/desk_meeting_zoom_hover.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'desk_small_horizontal':desk_small_horizontal,
    'desk_small_horizontal_hover':desk_small_horizontal_hover,
    'desk_small_vertical':desk_small_vertical,
    'desk_small_vertical_hover':desk_small_vertical_hover,
    'desk_zoom_0102':desk_zoom_0102,
    'desk_zoom_0102_hover':desk_zoom_0102_hover,
    'desk_zoom_0304':desk_zoom_0304,
    'desk_zoom_0304_hover':desk_zoom_0304_hover,
    'desk_zoom_0506':desk_zoom_0506,
    'desk_zoom_0506_hover':desk_zoom_0506_hover,
    'desk_zoom_0708':desk_zoom_0708,
    'desk_zoom_0708_hover':desk_zoom_0708_hover,
    'desk_zoom_0910':desk_zoom_0910,
    'desk_zoom_0910_hover':desk_zoom_0910_hover,
    'desk_zoom_1112':desk_zoom_1112,
    'desk_zoom_1112_hover':desk_zoom_1112_hover,
    'desk_zoom_1314':desk_zoom_1314,
    'desk_zoom_1314_hover':desk_zoom_1314_hover,
    'desk_zoom_1516':desk_zoom_1516,
    'desk_zoom_1516_hover':desk_zoom_1516_hover,
    'desk_zoom_1718':desk_zoom_1718,
    'desk_zoom_1718_hover':desk_zoom_1718_hover,
    'desk_zoom_1920':desk_zoom_1920,
    'desk_zoom_1920_hover':desk_zoom_1920_hover,
    'desk_zoom_2122':desk_zoom_2122,
    'desk_zoom_2122_hover':desk_zoom_2122_hover,
    'desk_zoom_2324':desk_zoom_2324,
    'desk_zoom_2324_hover':desk_zoom_2324_hover,
    'desk_zoom_2526':desk_zoom_2526,
    'desk_zoom_2526_hover':desk_zoom_2526_hover,
    'desk_zoom_2728':desk_zoom_2728,
    'desk_zoom_2728_hover':desk_zoom_2728_hover,
    'desk_zoom_2930':desk_zoom_2930,
    'desk_zoom_2930_hover':desk_zoom_2930_hover,
    'desk_zoom_3132':desk_zoom_3132,
    'desk_zoom_3132_hover':desk_zoom_3132_hover,
    'desk_zoom_3334':desk_zoom_3334,
    'desk_zoom_3334_hover':desk_zoom_3334_hover,
    'desk_zoom_3536':desk_zoom_3536,
    'desk_zoom_3536_hover':desk_zoom_3536_hover,
    'desk_meeting':desk_meeting,
    'desk_meeting_hover':desk_meeting_hover,
    'desk_meeting_zoom':desk_meeting_zoom,
    'desk_meeting_zoom_hover':desk_meeting_zoom_hover
};