import BannerJpg from '../assets/banner1.jpg';

const Banner = (props) => {
  
  const logEventToCSV = async (eventData) => {
    const csvData = `${eventData.date},${eventData.action},${eventData.table}\n`;

    try {
        const response = await fetch('./events.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain', // Or 'application/json' if sending JSON data
            },
            body: csvData,
        });

        if (response.ok) {
        } else {
        }
    } catch (error) {
    }
  };

  const handleLinkClick = (url) => {
    // Define the event data inside this function
    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'banner click'
    };

    logEventToCSV(eventData);
    
    // Navigate to the URL
    window.open(url, '_blank');
  };

  const renderImage = () => {
    const Images = [
      { image: <a onClick={() => handleLinkClick('https://bonaireisland.com/')}><img src={BannerJpg} alt="Logo" style={{cursor: 'pointer'}} height="auto" /></a> },
      { image: <a onClick={() => handleLinkClick('https://bonaireisland.com/')}><img src={BannerJpg} alt="Logo" style={{cursor: 'pointer'}} height="auto" /></a> }
    ];
    const randomImageIndex = Math.floor(Math.random() * Math.floor(2));
    return Images[randomImageIndex].image;
  };

  return (
    <>
      {renderImage()}
    </>
  )
}

export default Banner