import React, { useMemo, useState } from 'react';
import {IconButton, List, ListItemIcon, ListItemText, ListItem, Box, Divider, Drawer} from '@mui/material';
import { Link } from 'react-router-dom';
import burgerPng from '../assets/PNG/icn_menu.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Banner from './Banner';

const Header = (props) => {
  const theme = useTheme();
  const [state, setState] = useState({
    open: false
  });

  const banner = useMemo( () => <Banner />, [] );  

  const handleDrawerClose = () => {
    setState(false);
  };
  
  const toggleDrawer = (open) => (
    event: KeyboardEvent | MouseEvent
  ) => {
    setState({ ...state, right: open });
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  return (
    <div className={props.headerClass}>
      <div className='sponsor'>
        <span>With kind support from</span>
      </div>
      <Box marginLeft="auto" display="flex" sx={{ justifyContent: 'space-between' }}>
        <div className='banner'>
          {banner}
          
          <span onClick={toggleDrawer(true)}><img src={burgerPng} alt="Logo" height="auto" /></span>
        </div>
      </Box>

      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer(false)}
        sx={{
          width: "240px",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: "240px",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Impressum" component={Link} to="https://www.holland.com/de/tourist/impressum.htm" target="_blank" className='drawer-link'>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Impressum"/>
          </ListItem>
          <ListItem key="Datenschutz" component={Link} to="https://www.holland.com/de/tourist/privacy-statement.htm" target="_blank" className='drawer-link'>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Datenschutz" />
          </ListItem>
        </List>
        <div className='drawer-info'>Weitere Infos unter:<br /><a href="https://www.holland.com/itb" target="_blank">www.holland.com/itb</a></div>
      </Drawer>
    </div>
  )
}

export default Header